
import filters from "@/filters";
import { Component, Vue } from "vue-property-decorator";
import { Action } from "vuex-class";
import ModalRegistration from "@/components/landing/ModalRegistration.vue";
import AppLayout from "@/layouts/AppLayout.vue";
import { AxiosResponse } from "axios";
import { download } from "@/functions/global.function";
import toast from "@/functions/toast.function";

@Component({
  components: {
    AppLayout,
    ModalRegistration,
  },
  filters,
})
export default class EventDetail extends Vue {
  @Action("getAdminEventDetail", { namespace: "admin" })
  private getAdminEventDetail: any;

  private event: any = null;

  private created() {
    this.getData();
  }

  private getData() {
    this.getAdminEventDetail({
      params: {
        slug: this.$route.params.slug,
      },
      success: (response: AxiosResponse) => {
        this.event = response.data.data;
      },
    });
  }

  private onDownload(data: any) {
    download(data.attachment);
  }

  private openModalRegistration() {
    if (this.event.is_active) this.$bvModal.show("modal-registration");
    else toast.warning("Event telah berakhir.");
  }
}
