import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import EventDetail from "@/views/EventDetail.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/:slug",
    name: "event-detail",
    component: EventDetail,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: () => {
    return {
      x: 0,
      y: 0,
    };
  },
  routes,
});

export default router;
