
import Vue from "vue";
import Component from "vue-class-component";
import { SCHOOL, CANDIDATE, COMPANY } from "@/constants/portal.constant";

@Component
export default class AppFooter extends Vue {
  private goTo(portal: any) {
    switch (portal) {
      case "school":
        window.open("https://career.support/bkk", "_blank");
        // window.open(SCHOOL, "_blank");
        break;
      case "candidate":
        window.open(CANDIDATE, "_blank");
        break;
      case "company":
        window.open("https://company.career.support", "_blank");
        // window.open(COMPANY, "_blank");
        break;
      default:
        break;
    }
  }
}
