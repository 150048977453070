/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import "izitoast/dist/css/iziToast.min.css";
import iZtoast from "izitoast";

/* toast - START */
// Description : toast is for message info for make simple use iztoast.
// Author : Risman Abdilah.
// Created on : Fri, 18 Jun 2021.     Updated on : Fri, 18 Jun 2021.
// Created by : Risman Abdilah.       Updated by : Risman Abdilah.
/* Version : 1.0:1. */

const toast = {
  error: (message: any, title = "Error") => {
    return iZtoast.error({
      title,
      message,
      position: "bottomCenter",
    });
  },
  warning: (message: any, title = "Warning") => {
    return iZtoast.warning({
      title,
      message,
      position: "bottomCenter",
    });
  },
  success: (message: any, title = "Success") => {
    return iZtoast.success({
      title,
      message,
      position: "bottomCenter",
    });
  },
};

export default toast;

/* toast - END */
