import toast from "./toast.function";
import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

export const errorResponseHandler = (error: AxiosError): any => {
  if (error.response) toast.error(error.response.data.message);
  return Promise.reject(error);
};

export const responseHandler = (response: AxiosResponse): AxiosResponse => {
  return response;
};

export const requestHandler = (
  config: AxiosRequestConfig
): AxiosRequestConfig => {
  return config;
};
