import { ActionTree } from "vuex";
import { ActionCallback, RootState } from "./types";

export const actions: ActionTree<RootState, RootState> = {
  actionChangeColor({ commit }, callback: ActionCallback) {
    if (callback && callback.params) {
      commit("mutationColor", callback.params);
    } else {
      commit("mutationColor", 1);
    }
  },
  mainLayout({ commit }) {
    commit("mutationLayout", "main-layout");
  },
  contentLayout({ commit }) {
    commit("mutationLayout", "content-layout");
  },
};
