import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/css/app.css";

import "./registerServiceWorker";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

import i18n from "./i18n";
import filters from "./filters";

import AOS from "aos";

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import InfiniteSlideBar from "vue-infinite-slide-bar";
Vue.component("InfiniteSlideBar", InfiniteSlideBar);

AOS.init();

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.component("v-select", vSelect);

Vue.config.productionTip = false;

new Vue({
  filters,
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
