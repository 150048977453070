
import Vue from "vue";
import filters from "@/filters";
import Component from "vue-class-component";
import { Action } from "vuex-class";
import { AxiosResponse } from "axios";
import { Prop } from "vue-property-decorator";

@Component({
  filters: filters,
})
export default class ModalRegistration extends Vue {
  @Action("getSchoolType", { namespace: "admin" })
  private getSchoolType: any;
  @Action("buyAdminEvent", { namespace: "admin" })
  private buyAdminEvent: any;

  @Prop({ default: null })
  private event!: any;

  private schoolTypes: Array<any> = [];
  private name = "";
  private email = "";
  private phone = "";
  private schoolType: any = null;

  private created() {
    this.getSchoolType({
      success: (response: AxiosResponse) => {
        this.schoolTypes = response.data.data;
      },
    });
  }

  private onlyNumber(event: any) {
    let keyCode = event.keyCode ? event.keyCode : event.which;
    if (keyCode !== 43) {
      if (keyCode < 48 || keyCode > 57) {
        event.preventDefault();
      }
    }
  }

  private submit() {
    this.buyAdminEvent({
      params: {
        id: this.event?.id,
        name: this.name,
        email: this.email,
        phone: this.phone,
        school_type_id: this.schoolType?.id,
      },
      success: (response: AxiosResponse) => {
        if (response.data.data.url) window.open(response.data.data.url, "_self");
        else window.open(`${window.location.href}?name=${this.name}`, "_self");
      },
    });
  }
}
