
import Vue from "vue";
import $ from "jquery";
import Component from "vue-class-component";
import toast from "@/functions/toast.function";

@Component({
  components: {},
})
export default class AppNavbar extends Vue {
  private navbarToggle() {
    $("#main-nav").toggleClass("navbar-expanded");
    $("#nav-mobile").toggleClass("show");
  }

  private openModalRegistration() {
    document.getElementById("btn-registration")?.click();
  }
}
