import Axios, { AxiosResponse } from "axios";

export function download(url: string, filename: string = ""): void {
  const splitUrl = url.split("/");
  if (!filename) filename = splitUrl[splitUrl.length - 1];

  Axios.get(url, { responseType: "blob" })
    .then((res: AxiosResponse) => {
      const href = URL.createObjectURL(res.data);
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    })
    .catch((e) => console.warn("Unable to download file!", e));
}

export function onScroll(querySelector: string, callback: any = null): void {
  const element = document.querySelector(querySelector);
  if (element)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    element.onscroll = function () {
      if (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.scrollTop > this.scrollHeight - this.offsetHeight - 50 &&
        callback !== null
      )
        callback();
    };
}
