import { GetterTree } from "vuex";
import { RootState } from "./types";

export const getters: GetterTree<RootState, RootState> = {
  firstColor(state) {
    if (state.theme_color === 1) {
      return {
        text: "text-onebase_first",
        bg: "bg-onebase_first",
        border: "border-onebase_first",
      };
    } else if (state.theme_color === 2) {
      return {
        text: "text-twobase_first",
        bg: "bg-twobase_first",
        border: "border-twobase_first",
      };
    } else {
      return {
        text: "text-threebase_first",
        bg: "bg-threebase_first",
        border: "border-threebase_first",
      };
    }
  },
  twoColor(state) {
    if (state.theme_color === 1) {
      return {
        text: "text-onebase_second",
        bg: "bg-onebase_second",
        border: "border-onebase_second",
      };
    } else if (state.theme_color === 2) {
      return {
        text: "text-twobase_second",
        bg: "bg-twobase_second",
        border: "border-twobase_second",
      };
    } else {
      return {
        text: "text-threebase_second",
        bg: "bg-threebase_second",
        border: "border-threebase_second",
      };
    }
  },
  fontColor(state) {
    if (state.theme_color === 1) {
      return "text-onefont";
    } else if (state.theme_color === 2) {
      return "text-twofont";
    } else {
      return "text-threefont";
    }
  },
  whiteColor() {
    return { text: "text-white", bg: "bg-white", border: "border-white" };
  },
};
