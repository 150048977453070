import { AxiosResponse } from "axios";
import { BaseSchool } from "./bases/base.service";

export default class SchoolService {
  public getSchoolRequestDetail(params: any = {}): Promise<AxiosResponse<any>> {
    return BaseSchool().get(`request/detail/${params?.code}`, { params });
  }
  public schoolRequestRegister(params: any = {}): Promise<AxiosResponse<any>> {
    return BaseSchool().post("request/register", params);
  }
}
