import { ActionTree } from "vuex";
import { ActionCallback, RootState } from "../types";
import { AdminState } from "./types";
import AdminService from "@/services/admin.service";
import { AxiosError, AxiosResponse } from "axios";
const service: AdminService = new AdminService();

export const actions: ActionTree<AdminState, RootState> = {
  getIndustry(_, callback: ActionCallback) {
    service
      .getIndustry(callback?.params)
      .then((response: AxiosResponse) => {
        if (response) {
          if (callback && callback.success) callback.success(response);
        }
      })
      .catch((error: AxiosError) => {
        if (callback && callback.fail) callback.fail(error);
      })
      .finally(() => {
        if (callback && callback.finally) callback.finally();
      });
  },
  getCountry(_, callback: ActionCallback) {
    service
      .getCountry(callback?.params)
      .then((response: AxiosResponse) => {
        if (response) {
          if (callback && callback.success) callback.success(response);
        }
      })
      .catch((error: AxiosError) => {
        if (callback && callback.fail) callback.fail(error);
      })
      .finally(() => {
        if (callback && callback.finally) callback.finally();
      });
  },
  getProvince(_, callback: ActionCallback) {
    service
      .getProvince(callback?.params)
      .then((response: AxiosResponse) => {
        if (response) {
          if (callback && callback.success) callback.success(response);
        }
      })
      .catch((error: AxiosError) => {
        if (callback && callback.fail) callback.fail(error);
      })
      .finally(() => {
        if (callback && callback.finally) callback.finally();
      });
  },
  getCity(_, callback: ActionCallback) {
    service
      .getCity(callback?.params)
      .then((response: AxiosResponse) => {
        if (response) {
          if (callback && callback.success) callback.success(response);
        }
      })
      .catch((error: AxiosError) => {
        if (callback && callback.fail) callback.fail(error);
      })
      .finally(() => {
        if (callback && callback.finally) callback.finally();
      });
  },
  getDegree(_, callback: ActionCallback) {
    service
      .getDegree(callback?.params)
      .then((response: AxiosResponse) => {
        if (response) {
          if (callback && callback.success) callback.success(response);
        }
      })
      .catch((error: AxiosError) => {
        if (callback && callback.fail) callback.fail(error);
      })
      .finally(() => {
        if (callback && callback.finally) callback.finally();
      });
  },
  getMajor(_, callback: ActionCallback) {
    service
      .getMajor(callback?.params)
      .then((response: AxiosResponse) => {
        if (response) {
          if (callback && callback.success) callback.success(response);
        }
      })
      .catch((error: AxiosError) => {
        if (callback && callback.fail) callback.fail(error);
      })
      .finally(() => {
        if (callback && callback.finally) callback.finally();
      });
  },
  getSchoolType(_, callback: ActionCallback) {
    service
      .getSchoolType(callback?.params)
      .then((response: AxiosResponse) => {
        if (response) {
          if (callback && callback.success) callback.success(response);
        }
      })
      .catch((error: AxiosError) => {
        if (callback && callback.fail) callback.fail(error);
      })
      .finally(() => {
        if (callback && callback.finally) callback.finally();
      });
  },
  getAdminEventDetail(_, callback: ActionCallback) {
    service
      .getAdminEventDetail(callback?.params.slug)
      .then((response: AxiosResponse) => {
        if (response) {
          if (callback && callback.success) callback.success(response);
        }
      })
      .catch((error: AxiosError) => {
        if (callback && callback.fail) callback.fail(error);
      })
      .finally(() => {
        if (callback && callback.finally) callback.finally();
      });
  },
  buyAdminEvent(_, callback: ActionCallback) {
    service
      .buyAdminEvent(callback?.params)
      .then((response: AxiosResponse) => {
        if (response) {
          if (callback && callback.success) callback.success(response);
        }
      })
      .catch((error: AxiosError) => {
        if (callback && callback.fail) callback.fail(error);
      })
      .finally(() => {
        if (callback && callback.finally) callback.finally();
      });
  },
};
