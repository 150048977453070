import moment from "moment";

export default {
  date: (value: Date | string, format: string, locale: string = "en") => {
    if (value) {
      moment.locale(locale);
      if (format) {
        return moment(value).format(format);
      } else {
        return moment(value).format("DD/MM/YYYY");
      }
    }
    return "";
  },
  numberFormat: (value: number = 0): string => {
    return new Intl.NumberFormat().format(value);
  },
  time: (value: string) => {
    if (value) {
      const splitTime = value.split(":");
      const result = splitTime[0] + ":" + splitTime[1];

      const date = new Date();
      date.setHours(parseInt(splitTime[0]), parseInt(splitTime[1]));
      const dateEnd = new Date();
      dateEnd.setHours(date.getHours() + 1, date.getMinutes() + 30);
      const hourEnd =
        dateEnd.getHours() < 10 ? `0${dateEnd.getHours()}` : dateEnd.getHours();
      const minEnd =
        dateEnd.getMinutes() < 10
          ? `0${dateEnd.getMinutes()}`
          : dateEnd.getMinutes();

      const resultEnd = `${hourEnd}:${minEnd}`;
      return result.toString() + " - " + resultEnd.toString();
    }
    return "";
  },
};
