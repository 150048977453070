
import Vue from "vue";
import { State } from "vuex-class";
import { Component, Watch } from "vue-property-decorator";

@Component({
  components: {},
})
export default class App extends Vue {
  @State("locale")
  private locale!: string;

  @Watch("locale")
  private onLocaleChanged() {
    this.$i18n.locale = this.locale;
  }

  private created() {
    this.$i18n.locale = this.locale;
  }
}
