import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";
import { RootState } from "./types";
import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { admin } from "@/store/admin";
import { school } from "@/store/school";
import { candidate } from "@/store/candidate";
import { company } from "@/store/company";

Vue.use(Vuex);

const store: StoreOptions<RootState> = {
  modules: {
    admin,
    school,
    candidate,
    company,
  },
  state: {
    locale: "id",
    layout: "main-layout",
    theme_color: 1,
    app_login: null,
  },
  mutations,
  actions,
  getters,
};

export default new Vuex.Store<RootState>(store);
