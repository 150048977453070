
import { Component, Vue } from "vue-property-decorator";
import AppNavbar from "@/components/AppNavbar.vue";
import AppFooter from "@/components/AppFooter.vue";

@Component({
  components: {
    AppNavbar,
    AppFooter,
  },
})
export default class AppLayout extends Vue {}
