import { ActionTree } from "vuex";
import { ActionCallback, RootState } from "../types";
import { SchoolState } from "./types";
import SchoolService from "@/services/school.service";
import { AxiosError, AxiosResponse } from "axios";
import toast from "@/functions/toast.function";
const service: SchoolService = new SchoolService();

export const actions: ActionTree<SchoolState, RootState> = {
  getSchoolRequestDetail({ state }, callback: ActionCallback) {
    service
      .getSchoolRequestDetail(callback?.params)
      .then((response: AxiosResponse) => {
        if (response) {
          if (callback && callback.success) callback.success(response);
        }
      })
      .catch((error: AxiosError) => {
        if (callback && callback.fail) callback.fail(error);
      })
      .finally(() => {
        if (callback && callback.finally) callback.finally();
      });
  },
  schoolRequestRegister(_, callback: ActionCallback) {
    service
      .schoolRequestRegister(callback?.params)
      .then((response: AxiosResponse) => {
        if (response) {
          toast.success("Success.");
          if (callback && callback.success) callback.success(response);
        }
      })
      .catch((error: AxiosError) => {
        if (callback && callback.fail) callback.fail(error);
      })
      .finally(() => {
        if (callback && callback.finally) callback.finally();
      });
  },
};
